<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import countTo from "vue-count-to";
import Multiselect from "vue-multiselect";
import OrderReciept from "@/components/orderReciept";
import DatePicker from "vue2-datepicker";
// import { VFrappeChart } from 'vue-frappe-chart';

export default {
  page: {
    title: "Subsidy Report",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    OrderReciept,
    DatePicker,
    //VFrappeChart,
  },
  data() {
    return {
      cardData: [],
      corporateArr: [],
      corporateID: "",
      tableData: [],
      walletCardData: [],
      templateArr: [],
      restBranchArr: [],
      orderDetails: [],
      orderedItemArr: [],
      orderItemAddonArr: [],
      orderStatusArr: [],
      restBranchID: "",
      templateID: "",
      countryArr: [],
      country: "",
      transactionTypeArr: [
        { typeID: 1, name: "Credit" },
        { typeID: 2, name: "Debit" },
        { typeID: 3, name: "Expired" },
      ],
      typeID: "",
      dateFilter: [],
      branches: [],
      branchID: "",
      principleBranchID: "",
      principleBranches: [],
      cityArr: [],
      city: "",

      title: "Subsidy Statistics",
      items: [
        {
          text: "View",
        },
        {
          text: "Subsidy Statistics",
          active: true,
        },
      ],
      corpBranchID: "",
      walletStatData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 1000, 2000],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      cafeteriaArr: [],
      restBranch: "",
      mealPlanGraphArr: [],
      daterange: [],
      seriesMeal: [],
      chartOptionsMeal: {},
      fields: [
        {
          key: "employeeName",
          lable: "Customer Name",
          sortable: true,
        },
        {
          key: "employeeID",
          lable: "Customer ID",
          sortable: true,
        },
        {
          key: "mealPlan",
          sortable: true,
        },
        // {
        //   key:"dishName",
        //   sortable:true,
        // },
        {
          key: "orderID",
          sortable: true,
        },

        {
          key: "restaurant",
          sortable: true,
        },
        {
          key: "subsidy",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "gstAmount",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "subsidyPayable",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "serviceDate",
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.corpBranchID =
      this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 1 || this.$storageData.profile.empTypeID == 5
        ? this.brandLogin()
        : this.getStats();
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    if (this.$storageData.profile.empTypeID == 1) {
      this.getAllCountry();
    } else if (
      this.$storageData.profile.empTypeID == 2 ||
      this.$storageData.profile.empTypeID == 11
    ) {
      this.getCorporateCountry();
    } else if (this.$storageData.login_type == 4) {
      this.getPrincipleCountry();
    }
    // this.filteredMealPlanData();
    this.getCorporateCafeteria();

    ///this.readWalletStats();
  },
  methods: {
    /**
     * Search the table data with search input
     */

    filteredMealPlanData() {
      this.axios
        .post(this.$loggedRole+"/getMealPlanStatistics", {
          corpBranchID: sessionStorage.getItem("corpBranchID"),
          transactionType: this.typeID ? this.typeID.typeID : 0,
          dateFilter: this.dateFilter,
          restBranchID: sessionStorage.getItem("restBranchID"),
          orderDate: sessionStorage.getItem("date"),
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
        })
        .then((response) => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.cardData.datatableData;
          this.cardData = response.data.cardData;
          //console.log(this.tableData );
        });
    },

    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios.post(this.$loggedRole+"/getAllCountry").then((response) => {
        this.countryArr = response.data.data;
        // this.country = (this.countryArr) ? this.countryArr[0] : "";
        this.getAllCities();
      });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: this.country ? this.country.country : "",
        })
        .then((response) => {
          this.cityArr = response.data.data;
          //this.city = (this.cityArr) ? this.cityArr[0] : "";
          if (this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5) {
            this.getCorporateBranchByCorpID();
          }
        });
    },
    brandLogin() {
      this.getCorporateCountry();
    },
    getPrincipleCountry() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleCountry", {
          principleID: this.$storageData.profile.principleID,
          isDashboard: 1, // for all dropdown in only dashboards
        })
        .then((response) => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getPrincipleCities();
        });
    },
    getPrincipleCities() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleCities", {
          principleID: this.$storageData.profile.principleID,
          country: this.country ? this.country.country : "",
          isDashboard: 1, // for all dropdown in only dashboards
        })
        .then((response) => {
          this.cityArr = response.data.data;
          this.city = this.cityArr ? this.cityArr[0] : "";
          this.getPrincipleBranchByPrincipleID();
        });
    },
    getPrincipleBranchByPrincipleID() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID", {
          principleID: this.$storageData.profile.principleID,
          city: this.city ? this.city.city : "",
          isDashboard: 1, // for all dropdown in only dashboards
        })
        .then((response) => {
          this.principleBranches = response.data.data;
          // this.principleBranchID = this.principleBranches[0];
          // this.principleBranchIDSelected = this.principleBranchID.principleBranchID;
          // console.log(this.principleBranchID);
          // this.getPrincipleCorporateBranches();
        });
    },
    getPrincipleCorporateBranches() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleCorporateBranches", {
          principleBranchID: this.principleBranchID.principleBranchID,
        })
        .then((response) => {
          this.branches = response.data.data;
          this.branchID = this.branches ? this.branches[0] : [];
          //  this.principleCorporateStatistics();
        });
    },
    getCorporateCountry() {
      this.axios
        .post(this.$loggedRole+"/getCorporateCountry", {
          corporateID: this.$storageData.profile.corporateID,
          flag: this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5 ? 0 : 1, // for all dropdown in only dashboards
        })
        .then((response) => {
          this.countryArr = response.data.data;
          // this.country = (this.countryArr) ? this.countryArr[0] : "";
          this.getCorporateCities();
        });
    },
    getCorporateCities() {
      this.axios
        .post(this.$loggedRole+"/getCorporateCities", {
          corporateID: this.$storageData.profile.corporateID,
          flag: this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5 ? 0 : 1, // for all dropdown in only dashboards
          country: this.country ? this.country.country : "",
        })
        .then((response) => {
          this.cityArr = response.data.data;
          // this.city = (this.cityArr) ? this.cityArr[0] : "";
          this.getCorporateBranchByCorpID();
        });
    },
    getCorporateList() {
      this.corporateArr = [];
      this.corporateID = "";
      this.axios.get(this.$loggedRole+"/getCorporateList").then((response) => {
        this.corporateArr = response.data.data;
      });
    },

    getCorporateBranchByCorpID() {
      var corporateID = this.corporateID;
      if (
        this.$storageData.login_type == 3 ||
        this.$storageData.profile.empTypeID == 11 ||
        this.$storageData.profile.empTypeID == 5
      ) {
        corporateID = this.$storageData.profile.corporateID;
      }
      this.axios
        .post(this.$loggedRole+"/getCorporateBranchByCorpID", {
          corporateID: corporateID,
          flag: this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5 ? 0 : 1, // for all dropdown in only dashboards
          city: this.city ? this.city.city : "",
        })
        .then((response) => {
          this.branches = response.data.data;
          // this.branchID = this.branches[0];
          this.corpBranchID = this.branchID.corpBranchID;
          // this.getCorporateCafeteria(this.$storageData.profile.corpBranchID);
          this.getCorporateCafeteria();
        });
    },

    getCorporateCafeteria() {
      this.axios
        .post(this.$loggedRole+"/getCorporateCafeteria", {
          corpBranchID: this.$storageData.profile.corpBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
        })
        .then((result) => {
          this.cafeteriaArr = result.data.data;
          this.restBranch = this.cafeteriaArr ? this.cafeteriaArr[0] : "";
          this.restBranchID = this.restBranch.restBranchID;
          //alert(this.restBranchID);
          this.readMealplanStats();
          this.mealPlanGraph();
        });
    },

    onchangeBranch() {
      this.corpBranchID = this.branchID.corpBranchID;
      // this.readMealplanStats();
      // this.getCorporateCafeteria(this.branchID.corpBranchID);
      this.getCorporateCafeteria();
    },
    getStats() {
      this.corpBranchID = this.$storageData.profile.corpBranchID;
      this.readMealplanStats();
      this.mealPlanGraph();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    mealPlanGraph() {
      this.axios
        .post(this.$loggedRole+"/mealPlanGraph", {
          corpBranchID: this.$storageData.profile.empTypeID == 6 ? this.$storageData.profile.corpBranchID : this.$storageData.profile.corpBranchID,
          restBranchID: this.restBranchID,
          daterange: this.dateFilter,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
        })
        .then((result) => {
          // this.mealPlanGraphArr = result.data;
          // console.log(result.data);
          //    return;
          (this.seriesMeal = result.data.meals),
            (this.chartOptionsMeal = {
              chart: {
                type: "bar",
                height: 350,
                toolbar: {
                  show: true,
                },
                zoom: {
                  enabled: true,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "55%",
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
              xaxis: {
                categories: result.data.labels,
                // categories: ["25/08/2021", "26/08/2021", "27/08/2021", "28/08/2021", "29/08/2021", "30/08/2021", "31/08/2021","01/09/2021","02/09/2021","03/09/2021","04/09/2021","05/09/2021","06/09/2021"],
              },
              // yaxis: {
              //   title: {
              //     text: '$ (thousands)'
              //   }
              // },
              fill: {
                opacity: 1,
              },
              // tooltip: {
              // y: {
              //     formatter: function (val) {
              //     return "$ " + val + " thousands"
              //     }
              // }
              // }
            });
        });
    },

    // readWalletStats(){
    //     this.axios
    //     .post(
    //       "getWalletStatistics" ,
    //       {'corpBranchID':this.corpBranchID,
    //       'transactionType':(this.typeID) ? this.typeID.typeID : 0,
    //       'dateFilter':this.dateFilter,
    //       }
    //     )
    //     .then((response) => {
    //       //Then injecting the result to datatable parameters.

    //        this.tableData = response.data.data;
    //        this.walletCardData = response.data.walletCardData;
    //        this.walletStatData = response.data.walletStatData;
    //        //console.log((response));

    //     });
    // },

    readMealplanStats() {
      this.axios
        .post(this.$loggedRole+"/getMealPlanStatistics", {
          corpBranchID: this.$storageData.profile.corpBranchID,
          transactionType: this.typeID ? this.typeID.typeID : 0,
          dateFilter: this.dateFilter,
          restBranchID: this.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
        })
        .then((response) => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.cardData.datatableData;
          this.cardData = response.data.cardData;
          //console.log(this.tableData );
        });
    },

    onchangeRestBranch() {
      // this.restBranch = this.restBranch.restBranchID;
      this.corpBranchID =
        this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5
          ? this.branchID.corpBranchID
          : this.$storageData.profile.corpBranchID;
      this.readMealplanStats();
      this.mealPlanGraph();
    },
    applyFilter() {
      this.corpBranchID =
        this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 1 || this.$storageData.profile.empTypeID == 5
          ? this.branchID.corpBranchID
          : this.$storageData.profile.corpBranchID;
      this.getCorporateCafeteria(this.corpBranchID);
      this.readMealplanStats();
      this.mealPlanGraph();
    },
    clearFilter() {
      this.corporateID = 0;
      this.branchID = 0;
      this.typeID = 0;
      this.restBranch = "";
      this.restBranchArr = [];
      this.city = "";
      this.country = "";
      this.dateFilter = [];
      this.readMealplanStats();
      this.mealPlanGraph();
    },

    viewOrderDetails(orderID) {
      // alert(orderID);
      this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
      this.axios
        .post(this.$loggedRole+"/getOrderDetailsByID", { orderID: orderID })
        .then((result) => {
          this.orderDetails = result.data.data;
          this.orderStatusArr = result.data.data.orderStatus;
          //  console.log(result.data.data.orderedItemArr);
          this.orderedItemArr = result.data.data.orderedItemArr;
          // this.orderItemAddonArr = result.data.orderItemAddonArr;
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />

    <!-- FILTER START--->
    <div class="card" style="margin-top:-30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <div
            class="col-md-2"
            style="width:12%"
            v-if="
              this.$storageData.login_type == 3 ||
              this.$storageData.profile.empTypeID == 1 ||
              this.$storageData.profile.empTypeID == 5
            "
          >
            <label
              class="form-label"
              for="formrow-voucherType-input"
              style="font-size: 12px;"
              >Select Country
            </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              track-by="country"
              label="country"
              @input="
                this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5
                  ? getCorporateCities()
                  : getAllCities()
              "
              :show-labels="false"
            ></multiselect>
          </div>
          <div
            class="col-md-2"
            style="width:12%"
            v-if="
              this.$storageData.login_type == 3 ||
              this.$storageData.profile.empTypeID == 1 ||
              this.$storageData.profile.empTypeID == 5
            "
          >
            <label style="font-size: 12px;">Select City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              track-by="city"
              label="city"
              @input="getCorporateList()"
              :show-labels="false"
            ></multiselect>
          </div>
          <div
            class="col-md-2"
            style="width:20%"
            v-if="this.$storageData.profile.empTypeID == 1"
          >
            <label class="form-label" style="font-size: 12px;"
              >Select Corporate
            </label>
            <multiselect
              v-model="corporateID"
              :options="corporateArr"
              track-by="corporateID"
              label="corporateName"
              @input="getCorporateBranchByCorpID()"
              :show-labels="false"
            ></multiselect>
          </div>
          <div
            class="col-md-2"
            style="width:20%"
            v-if="
              this.$storageData.login_type == 3 ||
              this.$storageData.profile.empTypeID == 1 ||
              this.$storageData.profile.empTypeID == 5
            "
          >
            <label class="form-label" style="font-size: 12px;"
              >Select Corporate Branch
            </label>
            <multiselect
              v-model="branchID"
              :options="branches"
              track-by="corpBranchID"
              label="corpBranchName"
              @input="onchangeBranch()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- For priciples -->
          <div
            class="col-md-2"
            style="width:12%"
            v-if="
              this.$storageData.profile.empTypeID == 7 &&
                this.$storageData.login_type == 4
            "
          >
            <label>Select Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              track-by="country"
              label="country"
              @input="getPrincipleCities()"
              :show-labels="false"
            ></multiselect>
          </div>
          <div
            class="col-md-2"
            style="width:12%"
            v-if="
              this.$storageData.profile.empTypeID == 7 &&
                this.$storageData.login_type == 4
            "
          >
            <label>Select City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              track-by="city"
              label="city"
              @input="getPrincipleBranchByPrincipleID()"
              :show-labels="false"
            ></multiselect>
          </div>
          <div
            class="col-md-2"
            style="width:20%"
            v-if="
              this.$storageData.profile.empTypeID == 7 &&
                this.$storageData.login_type == 4
            "
          >
            <label>Select Principle Branch </label>
            <multiselect
              v-model="principleBranchID"
              :options="principleBranches"
              track-by="principleBranchID"
              label="principleBranchName"
              @input="getPrincipleCorporateBranches()"
              :show-labels="false"
            ></multiselect>
          </div>
          <div
            class="col-md-2"
            style="width:20%"
            v-if="
              this.$storageData.profile.empTypeID == 7 &&
                this.$storageData.login_type == 4
            "
          >
            <label class="form-label" style="font-size: 12px;"
              >Select Corporate Branch
            </label>
            <multiselect
              v-model="branchID"
              :options="branches"
              track-by="corpBranchID"
              label="corpBranchName"
              @input="onchangeBranch()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- For priciples -->
          <div class="col-sm-6 col-md-2" style="width:20%">
            <label
              class="form-label"
              style="font-size: 12px;"
              for="formrow-endTime-input"
              >Select Restaurant Branch</label
            >
            <multiselect
              v-model="restBranch"
              :options="cafeteriaArr"
              track-by="restBranchID"
              label="restaurantName"
              :show-labels="false"
              @input="onchangeRestBranch()"
            ></multiselect>
          </div>

          <div class="col-sm-6 col-md-3" style="width:20%">
            <label
              class="form-label"
              style="font-size: 12px;"
              for="formrow-date-input"
              >Date</label
            >
            <date-picker
              v-model="dateFilter"
              format="DD MMM Y"
              range
              append-to-body
              lang="en"
              value-type="format"
              confirm
              @input="applyFilter()"
            ></date-picker>
          </div>
          <div class="col-sm-6 col-md-1 mt-3">
            <button
              class="btn btn-themeBrown"
              style="padding: 4px 8px;margin-top: 16px;"
              @click="clearFilter()"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--FITER END--->

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-tabs
            justified
            nav-class="nav-tabs-custom"
            content-class="p-3 text-muted"
          >
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  >Subsidy Report Overview</span
                >
              </template>
              <div class="row" style="margin-top: 30px;">
                <div
                  class="col-md-6 col-xl-3"
                  v-if="
                    cardData.corpTotalPayable &&
                      this.$storageData.login_type == 4 &&
                      this.$storageData.profile.empTypeID == 7
                  "
                >
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{ cardData.corpTotalPayable }}
                          <!-- <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                        </span> -->
                        </h4>
                        <p class="text-muted mb-0">Total Subsidy Payable</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{ cardData.totalMealPlanOrders }}
                          <!-- <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                        </span> -->
                        </h4>
                        <p class="text-muted mb-0">Total Orders</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{ cardData.totalMealPlanOrders }}
                          <!-- <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                        </span> -->
                        </h4>
                        <p class="text-muted mb-0">Total Amount</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-6 col-xl-3"
                  v-for="data in cardData.mealsSeries"
                  :key="data.id"
                >
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{ data.count }}
                        </h4>
                        <p class="text-muted mb-0">{{ data.name }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="card">
                  <div class="card-body" style="min-height: 373px;">
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Subsidy Statistics</h6>
                      </div>
                      <!-- <div class="col-md-4">
                            
                                    <label class="form-label" for="formrow-endTime-input">Select Restaurant Branch*</label>
                                    <multiselect v-model="restBranch"  :options="cafeteriaArr" track-by="restBranchID" 
                                    label="restaurantName" :show-labels="false" @input="changeRestBranch"
                                    ></multiselect>
                            
                        </div>
                         <div class="col">
                             <router-link :to="{ name: 'mealPlanStatisticsTable', params: { id: this.corpBranchID } }">
                             <i class="mdi mdi-arrow-right" style="float: right;font-size: 21px;"></i>  
                            </router-link>
                        </div> -->
                    </div>
                    <apexchart
                      type="bar"
                      height="350"
                      :options="chartOptionsMeal"
                      :series="seriesMeal"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab @click="getCorporateCafeteria(corpBranchID)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  >Subsidy Report List</span
                >
              </template>
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div
                        class="col-sm-12 col-md-4"
                        style="margin-bottom:15px;"
                        v-if="this.$storageData.login_type == 1"
                      >
                        <div role="group" class="btn-group">
                          <button type="button" class="btn btn-themeBrown">
                            Excel
                          </button>
                          <!-- <button type="button" class="btn btn-themeBrown">
                            Column Visibility
                          </button> -->
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            Show &nbsp;&nbsp;
                            <b-form-select
                              style="margin-left:5px; margin-right:5px"
                              v-model="perPage"
                              size="sm"
                              :options="pageOptions"
                            ></b-form-select
                            >&nbsp;&nbsp;&nbsp; entries
                          </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-4 row">
                        <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-end"
                        >
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filter"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ms-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                        striped
                        hover
                        outlined
                        bordered
                        :items="tableData"
                        :fields="fields"
                        responsive
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                      >
                        <template v-slot:cell(transactionType)="data">
                          <span
                            v-if="data.item.isExpired == 1"
                            style="color:#F3766A;"
                          >
                            Expired
                          </span>
                          <span
                            v-else-if="data.item.transactionType == 1"
                            style="color:green;"
                          >
                            Credit
                          </span>
                          <span
                            v-else-if="data.item.transactionType == 2"
                            style="color:#A4827D;"
                          >
                            Debit
                          </span>
                        </template>

                        <template v-slot:cell(points)="data">
                          <span v-if="data.item.points"
                            >{{ data.item.points }}
                          </span>
                        </template>

                        <template v-slot:cell(orderID)="data">
                          <span
                            v-if="data.item.orderID"
                            style="cursor:pointer;color:#2069f1;"
                            @click="viewOrderDetails(data.item.id)"
                            >{{ data.item.orderID }}</span
                          >
                          <span v-else> - </span>
                        </template>
                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                          class="dataTables_paginate paging_simple_numbers float-end"
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="rows"
                              :per-page="perPage"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <!-- modals -->
    <OrderReciept
      :orderDetails="this.orderDetails"
      :orderedItemArr="this.orderedItemArr"
      :orderStatusArr="this.orderStatusArr"
    >
    </OrderReciept>
  </Layout>
</template>
